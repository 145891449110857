<template>
  <v-card 
    v-if="orderItems.length"
    flat
  >
    <v-card-text class="px-0 pt-5">
      <v-divider class="mb-5"></v-divider>
        <v-row class="px-1">
          <v-col cols="6">
            <b>ORDER TOTAL</b> (amount to be paid)
          </v-col>

          <v-col 
            cols="3"
            offset-md="3"
          >
            <p class="body-1 pa-0 ma-0"> 
              {{ total }}
            </p>
          </v-col>
        </v-row>
      <v-divider class="mt-5"></v-divider>
    </v-card-text>
  </v-card>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex';

export default {
  data () {
    return {
      total: 0,
    }
  },

  watch: {
    orderItems: {
      deep: true,
      handler: function (items) {
        const emptyItem = this.orderItems.find((item) => {
          return !item.price_uid || !item.quantity
        })

        if (!emptyItem) {
          this.requestPrice()
        }
      }
    }
  },
  
  computed: {
    ...mapGetters('retail', {
      orderItems: 'getOrderItems'
    }),
  },

  methods: {
    requestPrice: _.debounce(function () {
      console.log("lets go")
    }, 700),
  }
}
</script>